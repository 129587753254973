import { NavLink } from 'react-router-dom';
import Navigation from '../Nav/navbar';
import appoinmentBanner from '../OrderForm/Img/maltoDextrine.jpg'

const OrderForm = () => {
    return (
        <>
            {/*  <Navigation /> */}
            
            <section class="appoinment-area grey-bg mb-70   SliderSection"
                data-background="assets/img/shape/shape-bg-08.png">
                <div class="container mt-20 ">
                    <div class="row align-items-end py-5">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="appoint-thumb">
                                <img className='rounded' src={appoinmentBanner}
                                    alt="appoinment-img" />
                            </div>
                        </div>
                        <div class="col-xl-8 col-lg-12 col-md-12">
                            <div class="visitor-info visitor-info-bg shadow rounded">
                                <h4 class="appoinment-title mb-25">
                                    <i class="fa-light fa-file-signature"></i>Book your order
                                </h4>
                                <div class="visitor-form">
                                    <form action="#">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="visitor-form__input">
                                                    <input type="text"
                                                        placeholder="Your Name" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="visitor-form__input">
                                                    <input type="email"
                                                        placeholder="Your Email" />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="visitor-form__input">
                                                    <input type="text"
                                                        placeholder="Items Name" />
                                                </div>
                                            </div>
                                            {/* <div class="col-lg-6">
                                                <div class="visitor-form__input">
                                                    <input type="date" />
                                                </div>
                                            </div> */}
                                            <div class="col-lg-12">
                                                <div class="visitor-form__input">
                                                    <textarea placeholder="Item Description"
                                                        name="message"></textarea>
                                                </div>
                                            </div>
                                            <div class="header-cart-order d-flex align-items-center justify-content-end">
                                                <NavLink className="header-bottom-btn rounded  mb-3" href="about.html">Book Your Order</NavLink>
                                            </div>
                                            {/* <div class="col-lg-8 col-md-8 col-12">
                                                <div class="visit-serial mt-45">
                                                    <span>24/7 Emergency Service : <a
                                                        href="tel:25687140521">25687140521<i class="fa-regular fa-arrow-right"></i>
                                                    </a>
                                                    </span>
                                                </div>
                                            </div> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OrderForm;