import { BrowserRouter, Route, Routes } from "react-router-dom";
// import HomePage from "./pages/Home";
import '../src/assets/css/bootstrap.min.css';
import '../src/assets/css/animate.css';
import '../src/assets/css/swiper-bundle.css';
import '../src/assets/css/slick.css';
import '../src/assets/css/magnific-popup.css';
import '../src/assets/css/font-awesome-pro.css';
import '../src/assets/css/meanmenu.css';
import '../src/assets/css/nice-select.css';
import '../src/assets/css/flaticon.css';
import '../src/assets/css/spacing.css';
import '../src/assets/css/style.css';
import Nav from "./components/Nav/navbar";
import HomePage from "./pages/home";
import AboutUsPage from "./pages/aboutUs";
import Product from "./pages/product";
import ContactUs from "./pages/contact";
// import Navigation from "./components/Nav/navbar";
import OrderForm from "./components/OrderForm/bookOrder";
// import ScrollToTop from "./ScrollTop";



// import ""
// import ""


function App() {
  return (
    <>
      <BrowserRouter>
        {/* <Navbar /> */}
        <Routes>
          {/* <Route path='/' element={<Nav />} /> */}
          <Route path='/' element={<HomePage />} />
          <Route path='/about' element={<AboutUsPage />} />
          <Route path='/product' element={<Product />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/OrderForm' element={<OrderForm />} />


        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </>
  );
}

export default App;
