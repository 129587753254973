import { NavLink } from "react-router-dom";
import aboutImg2 from "../About/Img/about-bg-02.png"

const AboutComponent = () => {
    return (
        <>
            <section
                class="about-area grey-bg pt-100 tp-box-space pb-80 dataBackground">
                <div class="about-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-12 col-12">
                                <div
                                    class="about-thumb text-lg-center p-relative ml-85 mb-60 wow fadeInLeft"
                                    data-wow-delay=".3s">
                                    <img src={aboutImg2}
                                        width={435} alt="about-thumb" />

                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-12 col-12">
                                <div class="about-content ml-60 mb-60 wow fadeInRight"
                                    data-wow-delay=".3s">
                                    <div class="tp-section">
                                        <span
                                            class="tp-section__sub-title left-line mb-25">About
                                            Us</span>
                                        <h3 class="tp-section__title mb-20 fs-2">Best Glucose,Incense Sticks & Matchboxes Trader/Exporter In India</h3>
                                        <p class=" mr-20 mb-35" style={{ textAlign: "justify" }}>We are the best supplier of various products such as Liquid Glucose Regular, High Malto Corn Syrup, Sorbitol Oil, Maize Starch Powder, White Dextrin etc. We are exporters of high quality incense sticks and Matchboxes. Our goal is to reach customer satisfaction. We have an excellent team to manage our operations. For any type of inquiry, you can visit our official website <span style={{ color: "blue" }}>www.nirmalagencies.com</span> .</p>
                                    </div>
                                    <div class="about-content__btn">
                                        <NavLink to="/contact" className="tp-btn">Talk With experts</NavLink>
                                        {/* <a href="about.html"
                                        className="tp-btn">Talk with Expart</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const AboutComponent2 = (prop) => {
    return (
        <>

            <div class="tp-choose__item  mx-auto mb-100 wow fadeInUp" data-wow-delay=".2s">
                <div class="tp-choose__icon mb-40">
                    <i class={prop.icon}></i>
                </div>
                <div class="tp-choose__content">
                    <h4 class="tp-choose__title mb-20">{prop.heading_1} <br />{prop.heading_2}</h4>
                    <p>{prop.para1} <br />{prop.para2}</p>
                </div>
            </div>
        </>
    );
}

export default AboutComponent
export { AboutComponent2 }