const GalleryComponent = (prop) => {
    return (
        <>
            <div class="col-lg-4 col-md-6">
                <div class="gallery-item p-relative mb-30">
                    <img src={prop.galleryImg} alt="gallery-thumb" className="rounded-3" />
                    <div class="gallery-item__content">
                        <h4 class="gallery-item__title"><a href="#">{prop.galleryHeading}</a></h4>
                        <span><i class="fa-solid fa-tag"></i><a href="#">{prop.gallerySpan}</a></span>
                    </div>
                </div>
            </div>
        </>
    );
}

const GalleryComponent2 = (prop) => {
    return (
        <>
            <div class="col-lg-8 col-md-6 d-none d-lg-block">
                <div class="gallery-item p-relative mb-30">
                    <img src={prop.galleryImg2} alt="gallery-thumb" className="rounded-3" />
                    <div class="gallery-item__content">
                        <h4 class="gallery-item__title"><a href="#">{prop.galleryHeading}</a></h4>
                        <span><i class="fa-solid fa-tag"></i><a href="#">{prop.gallerySpan}</a></span>
                    </div>
                </div>
            </div>
        </>
    );
}
const GalleryImages = (prop) => {
    return (
        <>
            <div class="tpshopitem mb-50 wow fadeInUp" data-wow-delay=".2s">
                <div class="tpshopitem__thumb p-relative fix mb-35 ">
                    <img src={prop.productImg} alt="shop-thumb" className="rounded" />
                    <div class="tpshopitem__thumb-icon">
                        {/* <i data-toggle="modal" data-target="#myModal" class="fal fa-eye"></i> */}
                        {/* <a href="#"><i class="fal fa-shopping-cart"></i></a>
                        <a href="#"><i class="fal fa-heart"></i></a> */}
                        {/* <center data-toggle="modal" data-target="#myModal">aaaaaaa</center> */}
                    </div>
                </div>

            </div>


            <div class="modal fade " id="myModal">
                <div class="modal-dialog  modal-dialog-centered ">
                    <div class="modal-content">

                        {/* <!-- Modal Header --> */}
                        {/* <div class="modal-header">
                                    <div class="w-100">
                                        
                                        <a href="" class="text-decoration-none"><h6>To use<span style={{fontFamily:"satisfy"}}> myttube </span> on your computer</h6></a>

                                    </div>
                                </div> */}

                        {/* <!-- Modal body --> */}
                        <div class="modal-body">
                            <div class="">
                                <div className="scanner_div">
                                    <center>
                                        <div className="text-center top_p">
                                            <p className="mt-4">Scan the code to <br />download now app</p>
                                        </div>
                                        <div >
                                            <div>
                                                {/* <img src={QRCode} alt="" width="240px" /> */}

                                                <div className="d-flex justify-content-center mt-4">
                                                    {/* <center><img className="img-fluid mx-2" src={apple} alt="" /></center>
                                                        <center><img className="img-fluid mx-2" src={android} alt="" /></center> */}
                                                </div>
                                            </div>
                                            <div className="d-flex landing_btn">
                                                {/* <center data-toggle="modal" data-target="#myModal"><img className="img-fluid" src={apple} alt="" /></center> */}
                                                {/* <center><img className="img-fluid" src={android} alt="" /></center> */}
                                            </div>



                                        </div>
                                        <div className="text-center bottom_p">
                                            <p>USE THE APP NOW</p>
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Modal footer --> */}
                        {/* <div class="text-center p-5">
                                    <p><span class="text-primary">Need Help ?</span> How To Scan, See This Video Links <a href="https://www.youtube.com/aquibrahmani" target="_blank" class="text-decoration-none">https://www.youtube.com/aquibrahmani</a></p>
                                </div> */}

                    </div>
                </div>
            </div>
        </>
    );
}
export default GalleryComponent;
export { GalleryComponent2 };
export { GalleryImages };