// import React from 'react'
// import { Carousel } from 'react-bootstrap'
import React from 'react';
import ReactDOM from 'react-dom';


import sliderImg1 from '../Slider/Img/slider1.jpg'
import sliderImg2 from '../Slider/Img/slider2.jpg'
import sliderImg3 from '../Slider/Img/slider3.jpg'
import { CCarousel, CCarouselItem, CImage } from '@coreui/react';
import { CardGroup, Carousel } from 'react-bootstrap';

function Slider() {
  return (
    <>
      <section className='SliderSection Navmargin'>
        <div className='about-area grey-bg tp-box-space dataBackground'>
          {/* Slider Area */}
          <Carousel className="">
            <Carousel.Item >
              <CardGroup>
                <img src={sliderImg1} alt="Slider Image" className="img-fluid" />
              </CardGroup>
            </Carousel.Item>
            <Carousel.Item >
              <CardGroup>
                <img src={sliderImg2} alt="Slider Image" className="img-fluid" />
              </CardGroup>
            </Carousel.Item>
            <Carousel.Item >
              <CardGroup>
                <img src={sliderImg3} alt="Slider Image" className="img-fluid" />
              </CardGroup>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
    </>
  )
}

export default Slider