import logo from '../Nav/Img/Nirmallogo.png';
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap"
import TopBar from '../TopBg/topBar';
import { NavLink } from 'react-router-dom';


const Navigation = () => {
    return (
        <>


            <header class="d-none d-xl-block d-sm-none fixed-top bg-light ">
                <div class="header-custom" id="header-sticky" >
                    <div class="header-logo-box">
                        {/* <a href="/home"></a> */}
                        <NavLink to='/' ><img src={logo} alt="logo" /></NavLink>
                    </div>
                    <div class="header-menu-box">
                        <div class="header-menu-top">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="header-top-mob">
                                        <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="2" cy="2" r="2" fill="#0E63FF" /><circle cx="7" cy="2" r="2" fill="#0E63FF" /><circle cx="12" cy="2" r="2" fill="#0E63FF" /><circle cx="12" cy="7" r="2" fill="#0E63FF" /><circle cx="12" cy="12" r="2" fill="#0E63FF" /><circle cx="7" cy="7" r="2" fill="#0E63FF" /><circle cx="7" cy="12" r="2" fill="#0E63FF" /><circle cx="7" cy="17" r="2" fill="#0E63FF" /><circle cx="2" cy="7" r="2" fill="#0E63FF" /><circle cx="2" cy="12" r="2" fill="#0E63FF" /></svg>
                                        <span>Help Desk :</span>
                                        <a href="tel:02512568714"> 0251 - 2568714 | +91 8433604616 </a>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="header-time">
                                        <span><i class="fa-light fa-clock-ten"></i> Saturday - Thursday ( 10:00 am - 06:00 Pm )</span>
                                        {/* <span>Monday - Friday  09:00 am - 05:00 Pm</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="header-menu-bottom">
                            <div class="row">
                                <div class="col-lg-7">
                                    <div class="main-menu main-menu-second">
                                        <nav id="mobile-menu">
                                            <ul>
                                                <li><NavLink to="/" >Home</NavLink></li>
                                                <li><NavLink to="/about" >About</NavLink></li>
                                                <li><NavLink to="/product" >Product</NavLink></li>
                                                <li><NavLink to="/contact" >Contact</NavLink></li>

                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                {/* 
                                <div class="col-lg-5">
                                    <div class="header-cart-order d-flex align-items-center justify-content-end">
                                        <NavLink to="/OrderForm" className="header-bottom-btn" >Book Your Order</NavLink>
                                    </div>
                                </div>
                                */}
                            </div>
                        </div>
                    </div>

                </div>
            </header >
            <Navbar expand="lg" className="bg-body-tertiary ">
                <Container>

                    <NavLink to='/' ><img width={150} src={logo} alt="logo" /></NavLink>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <NavLink className='text-dark pt-2' to="/">Home</NavLink>
                            <NavLink className='text-dark pt-2' to="/about">About</NavLink>
                            <NavLink className='text-dark pt-2' to="/product">Product</NavLink>
                            <NavLink className='text-dark pt-2 pb-2' to="/contact">Contact</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>



        </>

    );
}
export default Navigation