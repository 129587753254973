import React from 'react'
import TopBar from '../components/TopBg/topBar'
import aboutBg4 from '../components/About/Img/about-bg-04.jpg'
import aboutBg5 from '../components/About/Img/about-bg-05.jpg'
import aboutBg6 from '../components/About/Img/about-bg-06.jpg'
import aboutBg7 from '../components/About/Img/abouimg1.jpg'
import Navigation from '../components/Nav/navbar'
import { AboutComponent2 } from '../components/About/about'
import Footer from '../components/Footer/Footer'
import { NavLink } from 'react-router-dom'
// import { AboutComponent2 } from '../components/About/about'
// import aboutBg7 from '../../assets/img/about/about-bg-07.jpg'


function AboutUsPage() {
    return (
        <>
            <Navigation />
            <TopBar heading="About us" />

            {/* <!-- about-area --> */}
            <section class="about-area pt-60 pb-30">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6 col-lg-4 col-12">
                            <div class="tp-about-thumb mb-60 wow fadeInLeft" data-wow-delay=".3s">
                                <div class="tp-ab-img d-flex">
                                    <div class="tp-ab-main-img p-relative">
                                        <img src={aboutBg4} className='shadow-lg border border-1 border-dark' alt="about-thumb" />
                                        <div class="bg-transparent about__exprience tp-ab-counter">
                                            <img width={220} class="ab-shape-two shadow-lg border border-1 border-dark" src={aboutBg7} alt="" />
                                        </div>
                                    </div>
                                    <div class="tp-ab-shape d-none d-md-block d-lg-none d-xl-block">
                                        <img class="ab-shape-one" className='shadow-lg border border-1 border-dark mb-10' src={aboutBg5} alt="about-shape" />
                                        <img class="ab-shape-two" className='shadow-lg border border-1 border-dark' src={aboutBg6} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-8 col-12">
                            <div class="about-content about-align mb-60 wow fadeInRight" data-wow-delay=".3s">
                                <div class="tp-section">
                                    <h3 class="tp-section__title ab-title mb-25 fs-2">Best Glucose,Incense Sticks & Matchboxes Trader/Exporter In India</h3>
                                    <a class="tp-section__link" href="#">Read our MIssion & Vission <i class="fa-solid fa-arrow-right"></i></a>
                                    {/* <i>We hope you enjoy our products as much as we enjoy.</i> */}
                                    <p class=" mr-20 mb-35" style={{ textAlign: "justify" }}>We are the best supplier of various products such as Liquid Glucose Regular, High Malto Corn Syrup, Sorbitol Oil, Maize Starch Powder, White Dextrin Powder etc. We are exporters of high quality incense sticks and Matchboxes. Our goal is to reach customer satisfaction. We have an excellent team to manage our operations. For any type of inquiry, you can visit our official website <span style={{ color: "blue" }}>www.nirmalagencies.com</span> .</p>
                                    <a class="tp-section__link" href="#">Our Valuable Products   <i class="fa-solid fa-arrow-down"></i></a>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div class="tp-about__info-list ab-check-list mb-55">
                                            <ul>
                                                <li><i class="fa-solid fa-check"></i>LIQUID GLUCOSE</li>
                                                <li><i class="fa-solid fa-check"></i>DEXTROSE MONOHYDRATE</li>
                                                <li><i class="fa-solid fa-check"></i>SORBITOL OIL</li>
                                                <li><i class="fa-solid fa-check"></i>INCENSE STICKS</li>
                                                {/* <li><i class="fa-solid fa-check"></i>Sweet 300 Litre Liquid Glucose</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div class="tp-about__info-list ab-check-list mb-55">
                                            <ul>
                                                <li><i class="fa-solid fa-check"></i>MAIZE STARCH POWDER</li>
                                                <li><i class="fa-solid fa-check"></i>MALTO DEXTRINE POWDER</li>
                                                <li><i class="fa-solid fa-check"></i>GLUCOSE "D" POWDER</li>
                                                <li><i class="fa-solid fa-check"></i>MATCHBOXES</li>
                                                {/* <li><i class="fa-solid fa-check"></i>Tirupati Liquid Glucose</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="about-content__btn tp-btn"><NavLink to="/contact" classNames="tp-btn">Contact us</NavLink></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- about-area-end --> */}

            <section class="choose-area theme-bg pt-50 pb-20">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="tp-section text-center">
                                <span class="tp-section__sub-title left-line right-line mb-25 ml-15">Our Specialists</span>
                                <h3 class="tp-section__title title-white mb-85">Why Choose Us</h3>
                            </div>
                        </div>
                    </div>
                    <div class="row ml-100">
                        <div class="col-xl-3 col-md-6">
                            <AboutComponent2 icon="flaticon-microscope" heading_1="High Quality" heading_2="Services" para1="We check our product before" para2="delivery to customer end." />
                        </div>
                        <div class="col-xl-3 col-md-6">
                            <AboutComponent2 icon="flaticon-thinking" heading_1="Fast Working " heading_2="Process" para1="Due to our dedicaated team members," para2="we provide the material on time." />
                        </div>
                        <div class="col-xl-3 col-md-6">
                            <AboutComponent2 icon="flaticon-24-hours-1" heading_1="Best Customer" heading_2="Support" para1="We always ready to solve our" para2="customer query." />
                        </div>
                        <div class="col-xl-3 col-md-6">
                            <AboutComponent2 icon="flaticon-team" heading_1="We have" heading_2="Expert Team" para1="We are having excellent Team member in " para2="respective area to grow our business." />
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default AboutUsPage