import { Container, Nav, NavDropdown, NavLink, Navbar } from "react-bootstrap"
import Navigation from "../components/Nav/navbar"
import logo from '../components/Nav/Img/Nirmallogo.png';
import Slider from "../components/Slider/Slider";
import AboutUs from "../components/About/about";
import Gallery from "../components/Gallery/gallery";
import OrderForm from "../components/OrderForm/bookOrder";
import Footer from "../components/Footer/Footer";

const HomePage = () => {
    return (
        <>
            <Navigation /> 
            <Slider /> 
            <AboutUs />
            <Gallery />
            <OrderForm />
            <Footer />

        </>
    )
}

export default HomePage