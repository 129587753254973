const TopBar = (prop) => {
    return (
        <>
            {/* <!-- breadcrumb-area --> */}
            <section class="breadcrumb__area breadcrumb__overlay SliderSection dataBackground3 back">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-7 col-lg-12 col-md-12 col-12">
                            <div class="tp-breadcrumb">
                                <h2 class="tp-breadcrumb__title">{prop.heading}</h2>
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-12 col-md-12 col-12">
                            <div class="tp-breadcrumb__link text-xl-end">
                                <span>Nirmal Agencies : {prop.heading}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- breadcrumb-area-end --> */}
        </>
    );
}

export default TopBar;