import Contact from "../components/Contact/contact";
import Footer from "../components/Footer/Footer";
import Navigation from "../components/Nav/navbar";
import TopBar from "../components/TopBg/topBar";

// import contactIcon from '../../assets/img/icon/contact-01.svg'
// import contactIcon2 from '../../assets/img/icon/contact-02.svg'
// import contactIcon3 from '../../assets/img/icon/contact-03.svg'

const ContactUs = () => {
    return (
        <>
            <Navigation />
            <TopBar heading="Contact Us" />
            <Contact />
            <Footer />

        </>
    );
}

export default ContactUs;