import React from 'react'

import galleryThumb1 from '../Gallery/Img/gallery-thumb-bg-1.png'
import galleryThumb2 from '../Gallery/Img/gallery-thumb-bg-2.jpg'
import galleryThumb3 from '../Gallery/Img/gallery-thumb-bg-3.png'
import galleryThumb4 from '../Gallery/Img/gallery-thumb-bg-4.png'
import galleryThumb5 from '../Gallery/Img/gallery-thumb-bg-5.png'
import galleryThumb6 from '../Gallery/Img/match_boxes.png'
import galleryThumb7 from '../Gallery/Img/lovendor-stick.png'
// import GalleryComponent, { GalleryComponent2 } from './GalleryComponent'
// import { ExploreMore } from '../Button/Button'
import GalleryComponent, { GalleryComponent2 } from './galleryComponent'
import { NavLink } from 'react-router-dom'

function Gallery() {
    return (
        <>
            {/* <!-- gallery-area --> */}
            <section class="gallery-area pb-20">
                <div
                    class="gallery-bg-title theme-light-bg tp-box-space pt-50 pb-200 mr-30 ml-30 dataBackground">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="tp-section text-center">
                                    <span class="tp-section__sub-title sub-title-white left-line-white right-line-white mb-25">
                                        Work Gallery
                                    </span>
                                    <h3 class="tp-section__title title-white mb-80">
                                        Nirmal Agencies Gallery
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gallery-item-bg wow fadeInUp" data-wow-delay=".3s">
                    <div class="container">
                        <div class="row">
                            <GalleryComponent galleryImg={galleryThumb1} galleryHeading="GLUCOSE POWDER REGULAR" gallerySpan="300 KG" />
                            <GalleryComponent galleryImg={galleryThumb4} galleryHeading="MAIZE STARCH POWDER" gallerySpan="300 KG" />
                            <GalleryComponent galleryImg={galleryThumb3} galleryHeading="SORBITAL OIL" gallerySpan="300 KG" />
                            {/* <GalleryComponent2 galleryImg2={galleryThumb2} galleryHeading="HIGH MALTO CORN  " gallerySpan="300 KG" /> */}
                            <GalleryComponent galleryImg={galleryThumb4} galleryHeading="MAIZE STARCH POWDER" gallerySpan="300 KG" />
                            <GalleryComponent galleryImg={galleryThumb6} galleryHeading="MATCHBOXES" gallerySpan="" />
                            <GalleryComponent galleryImg={galleryThumb7} galleryHeading="FLORAL STICKS" gallerySpan="" />
                        </div>
                        <div class="gallery-btn text-center mt-30">
                            <NavLink to="/product" className="tp-btn">Explore More</NavLink>
                        </div>
                        {/* <ExploreMore ExploreMore="Explore More" /> */}
                    </div>
                </div>
            </section>
            {/* <!-- gallery-area-end --> */}
        </>
    )
}

export default Gallery;