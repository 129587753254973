import { GalleryImages } from "../components/Gallery/galleryComponent";
import Navigation from "../components/Nav/navbar";
import TopBar from "../components/TopBg/topBar";

import product1 from '../components/Gallery/Img/1.png'
import product2 from '../components/Gallery/Img/2.png'
import product3 from '../components/Gallery/Img/3.png'
import product4 from '../components/Gallery/Img/4.png'
import product5 from '../components/Gallery/Img/5.png'
import product6 from '../components/Gallery/Img/6.png'
import product7 from '../components/Gallery/Img/7.png'
import product8 from '../components/Gallery/Img/8.png'
import product9 from '../components/Gallery/Img/9.png'
import product10 from '../components/Gallery/Img/10.png'
import product11 from '../components/Gallery/Img/11.png'
import product12 from '../components/Gallery/Img/12.png'
import Footer from "../components/Footer/Footer";

const Product = () => {
    return (
        <>


            <Navigation />
            <TopBar heading="Our Product" />
            <div class="shop-area pt-120 pb-30">
                <div class="container">

                    <div class="row">
                        <div class="col-xl-3 col-lg-4 col-md-4">
                            <GalleryImages productImg={product1} productName="LIQUID GLUCOSE REGULAR" price="$58.10" />
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-4">
                            <GalleryImages productImg={product2} productName="LIQUID GLUCOSE REGULAR" price="$58.10" />
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-4">
                            <GalleryImages productImg={product3} productName="LIQUID GLUCOSE REGULAR" price="$58.10" />
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-4">
                            <GalleryImages productImg={product4} productName="LIQUID GLUCOSE REGULAR" price="$58.10" />
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-4">
                            <GalleryImages productImg={product5} productName="LIQUID GLUCOSE REGULAR" price="$58.10" />
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-4">
                            <GalleryImages productImg={product6} productName="LIQUID GLUCOSE REGULAR" price="$58.10" />
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-4">
                            <GalleryImages productImg={product7} productName="LIQUID GLUCOSE REGULAR" price="$58.10" />
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-4">
                            <GalleryImages productImg={product8} productName="LIQUID GLUCOSE REGULAR" price="$58.10" />
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-4">
                            <GalleryImages productImg={product9} productName="Incense Sticks" price="$58.10" />
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-4">
                            <GalleryImages productImg={product10} productName="Match Boxes" price="$58.10" />
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-4">
                            <GalleryImages productImg={product11} productName="Incense Sticks" price="$58.10" />
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-4">
                            <GalleryImages productImg={product12} productName="Match Boxes" price="$58.10" />
                        </div>
                    </div>

                </div>
            </div >

            <Footer />
        </>
    );
}

export default Product