import React from 'react'
import logo from '../Nav/Img/Nirmallogo.png';
import { NavLink } from 'react-router-dom';

function Footer() {
    return (
        <>
            {/* footer-area  */}
            <footer>
                <div class="footer-area ">
                    <div className='container mt-5'></div>
                    <div class=" fw-border">
                        <div class="container ">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 ">
                                    <div class="tp-footer-widget footer-2-col-1  wow fadeInUp"
                                        data-wow-delay=".2s">
                                        <div class="tp-footer-widget__content">
                                            <img className='w-75 mt-10' src={logo} alt="" />
                                        </div>
                                        <div class="tp-footer-widget__sub-sec">
                                            {/* <span class="tp-footer-widget__sub-title mb-10">Career</span> */}
                                            <p className='w-75 mt-15' style={{ textAlign: "justify" }}>We are best supplier of different products like Liquid Glucose Regular, High Malto Corn Syrup, Sorbitol Oil, White Dextrine etc..</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="tp-footer-widget footer-2-col-2  wow fadeInUp"
                                        data-wow-delay=".4s">
                                        <span class="tp-footer-widget__title mb-15">Useful
                                            links</span>
                                        <div class="tp-footer-widget__links ">
                                            <ul>
                                                <li><NavLink to="/" >Home</NavLink></li>
                                                <li><NavLink to="/about" >About us</NavLink></li>
                                                <li><NavLink to="/product" >Our Product</NavLink></li>
                                                <li><NavLink to="/contact" >Contact us</NavLink></li>
                                                {/* <li><NavLink to="/" >Our Policy</NavLink></li>
                                                <li><NavLink to="/help" >Help</NavLink></li> */}

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-6 ">
                                    <div
                                        class="tp-footer-widget footer-2-col-3  wow fadeInUp"
                                        data-wow-delay=".6s">
                                        <span class="tp-footer-widget__title mb-15">Our Valuable Products</span>
                                        <div class="tp-footer-widget__links">
                                            <ul>
                                                <li><NavLink to="/product" >Liquid Glucose</NavLink></li>
                                                <li><NavLink to="/product" >Maize Starch Powder</NavLink></li>
                                                <li><NavLink to="/product" >Dextrose Monohydrate</NavLink></li>
                                                <li><NavLink to="/product" >Malto Dextrine Powder</NavLink></li>
                                                <li><NavLink to="/product" >Incense Sticks & Matchboxes-अगरबत्तियां</NavLink></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 ">
                                    <div
                                        class="tp-footer-widget footer-2-col-4  wow fadeInUp"
                                        data-wow-delay=".8s">
                                        <span class="tp-footer-widget__title mb-15">Contact
                                            Info</span>
                                        <div class="tp-footer-widget__links">
                                            <ul>
                                                <li className='mt-2'>Shop No. 8, Vikas Tower, Near Powai Chowk, Vithalwadi Station Road
                                                    Ulhasnagar-3, 421003</li>
                                                {/* <li><a href="tel:25687145134">2568 7145 134</a></li> */}
                                                {/* <li><a href="tel:25687145134">2568 7140 251</a></li> */}
                                                <li className='mt-2'>
                                                    <a href="https://html.weblearnbd.net/cdn-cgi/l/email-protection#0675737676697472467475726e636b632865696b"><span
                                                        class="__cf_email__" data-cfemail="691a1c1919061b1d291b1a1d010c040c470a0604">nirmalagencies2022@gmail.com</span></a></li>
                                                <li className='mt-2'>Office Hours: 10 AM - 06 PM</li>
                                                {/* <li>Saturday - Thursday</li> */}
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer-area-bottom fw-border" style={{ backgroundColor: "#010050" }}>
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                    <center><div class="footer-widget__copyright copyright-white">
                                        <span> © Copyright ©2023 <a href="https://aakaarinfotech.com/" className='text-warning'>Aakaar Infotech </a>
                                            <i className='text-light'>All Rights Reserved..</i></span>
                                    </div></center>
                                </div>
                                {/* <div class="col-xl-6 col-lg-6 col-md-12 col-12">
                                    <div
                                        class="footer-widget__copyright-info info-direction">
                                        <ul class="d-flex align-items-center">
                                            <li><NavLink to="/termsAndConditions" >Terms and conditions</NavLink></li>
                                            <li><NavLink to="/privacyPolicy" >Privacy policy</NavLink></li>
                                            <li><NavLink to="/pricing" >Pricing</NavLink></li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
            {/* footer-area-end  */}
        </>
    )
}

export default Footer;
