import { NavLink } from 'react-router-dom';
import contactIcon from '../Contact/Img/contact-01.svg'
import contactIcon2 from '../Contact/Img/contact-02.svg'
import contactIcon3 from '../Contact/Img/contact-03.svg'

const Contact = () => {
    return (
        <>
            <section class="contact-area pt-130 pb-30">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-5 col-12 wow fadeInLeft" data-wow-delay=".4s">
                            <div class="tpcontact mr-60 mb-60 wow fadeInUp" data-wow-delay=".2s">
                                <div class="tpcontact__item text-center">
                                    <div class="tpcontact__icon mb-20">
                                        <img src={contactIcon} alt=" contact icon" />
                                    </div>
                                    <div class="tpcontact__address">
                                        <h4 class="tpcontact__title mb-15">Address line:</h4>
                                        <span><a href="contact.html">Shop No. 8, Vikas Tower, Near Powai Chowk, Vithalwadi Station Road
                                            Ulhasnagar-3, 421003</a></span>
                                    </div>
                                </div>
                            </div>
                            <div class="tpcontact mr-60 mb-60 wow fadeInUp" data-wow-delay=".4s">
                                <div class="tpcontact__item text-center">
                                    <div class="tpcontact__icon mb-20">
                                        <img src={contactIcon2} alt="contact icon" />
                                    </div>
                                    <div class="tpcontact__address">
                                        <h4 class="tpcontact__title mb-15">Phone Number</h4>
                                        <span><a href="tel:02512568714">0251 - 2568714</a></span>
                                        <span><a href="tel:02512565134">0251 - 2565134</a></span>
                                        <span><a href="tel:91 8433604616">+91 8433-604-616</a></span>
                                    </div>
                                </div>
                            </div>
                            <div class="tpcontact mr-60 mb-60 wow fadeInUp" data-wow-delay=".6s">
                                <div class="tpcontact__item text-center">
                                    <div class="tpcontact__icon mb-20">
                                        <img src={contactIcon3} alt="contact icon" />
                                    </div>
                                    <div class="tpcontact__address">
                                        <h4 class="tpcontact__title mb-15">Opening Hours</h4>
                                        <span>Saturday - Thursday<br />10:00 AM - 06:00 PM</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-7 col-12">
                            <div class="contactform wow fadeInRight" data-wow-delay=".4s">
                                <h4 class="contactform__title mb-35">Send us a message :</h4>
                                <div class="contactform__list mb-30">
                                    <form id="contact-form" action="https://html.weblearnbd.net/bioxlab/bioxlab/assets/mail.php" method="post">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="contactform__input mb-30">
                                                    <input name="name" type="text" placeholder="Enter your Name" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="contactform__input mb-30">
                                                    <input name="email" type="email" placeholder="Enter your mail" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="contactform__input mb-30">
                                                    <input name="number" type="text" placeholder="Enter your number" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="contactform__input mb-30">
                                                    <input name="website" type="text" placeholder="Enter your website" />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="contactform__input mb-30">
                                                    <textarea name="message" placeholder="Type your comment"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="contactform__input mb-30-btn">

                                                    <NavLink className="tp-btn">send message</NavLink>
                                                </div>
                                                <p class="ajax-response"></p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="tpcontactmap">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.4743108192724!2d73.16071157475128!3d19.218149747495467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be794433b97ac4b%3A0x9272c022be71c6bb!2sNirmal%20Agencies!5e0!3m2!1sen!2sin!4v1695923098926!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact